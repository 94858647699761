<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <!-- <debug-btn :item="responseData"></debug-btn> -->
          <template v-slot:heading>
            <h3>{{title}}</h3>
          </template>
          <v-btn-toggle mandatory v-model="triwulan" class="mt-5">
            <v-btn value="1">TRIWULAN 1</v-btn>
            <v-btn value="2">triwulan 2</v-btn>
            <v-btn value="3">triwulan 3</v-btn>
            <v-btn value="4">triwulan 4</v-btn>
            <v-btn value="tahun">tahunan</v-btn>
          </v-btn-toggle>
          <!-- <v-row> -->
            <!-- <v-col v-if="pegawai.length > 0">
              <div v-for="(peg,i) in pegawai" :key="i + 'a'"> -->
                <v-row v-if="loadingBtn.dataPegawai" class="mt-5">
                  <v-col v-for="i in [1,2,3,4,5,6]" :key="i">
                    <v-skeleton-loader
                    class="mx-auto"
                    min-width="300"
                    type="card"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
                <v-row v-if="pegawai.length > 0" class="mt-10">
                  <v-col v-for="(peg,i) in pegawai" :key="i" align="center">
                    <base-material-card
                      class="v-card-profile"
                      :avatar="peg.foto"
                      style="width:270px; "
                    >
                      <v-card-text class="text-center" height='fit-content'>
                        <h4 class="display-2 mb-1 orange--text text--darken-2" style="height:55px;">
                          {{peg.nama}}
                        </h4>
                        <h4 class="font-weight-light grey--text mb-2">
                          NIP. {{peg.nip}}
                        </h4>
                        <h4 class="font-weight-light green--text" style="font-size:12px; height:30px">
                          {{peg.jabatan}}
                        </h4>
                        <div class="mt-5">
                          <!-- <v-chip small label :class="reviewStatus(peg)" class="ma-1" dark>{{peg.status}}</v-chip>
                          <v-chip small label class="ma-1 orange darken-2" dark>{{peg.jumlah}}/{{peg.harus}} Pertanyaan</v-chip> -->
                        </div>
                      </v-card-text>
                      <v-card-actions class="d-flex justify-center">
                        <div v-if="(periode_isi_ekspektasi && (triwulan_enable == triwulan))">
                          <v-btn
                          color="cyan darken-4"
                          dark
                          rounded
                          class="mr-0"
                          :loading="loadingEl == i"
                          @click="openDialogEkspektasiPerilaku(peg, i, false)"
                          :small="true"
                          >
                          <span> Isi Ekspektasi Perilaku </span>
                          </v-btn>
                        </div>

                        <div v-if="enable.buka_ekspektasi_perilaku_tw1 && triwulan == '1' ||
                                  enable.buka_ekspektasi_perilaku_tw2 && triwulan == '2' ||
                                  enable.buka_ekspektasi_perilaku_tw3 && triwulan == '3' ||
                                  enable.buka_ekspektasi_perilaku_tw4 && triwulan == '4' ||
                                  enable.buka_ekspektasi_perilaku_tahunan && triwulan == 'tahun'">
                          <v-btn
                            color="cyan darken-4"
                            dark
                            rounded
                            class="mr-0"
                            :loading="loadingEl == i"
                            @click="openDialogEkspektasiPerilaku(peg, i, false)"
                            :small="true"
                            >
                            <span> Isi Ekspektasi Perilaku </span>
                          </v-btn>
                        </div>
                        <div v-else>
                        </div>
                      </v-card-actions>
                    </base-material-card>
                  </v-col>
                </v-row>
                <v-row v-else justify="center">
                  <v-col cols="5" v-if="loadingBtn.dataPegawai == false">
                    <v-alert
                    color="blue-grey"
                    dark
                    dense
                    icon="mdi-account-group"
                    prominent
                  >
                    Tidak ada pegawai
                  </v-alert>
                  </v-col>
                </v-row>

                <div v-if="current.role != 'walikota'">
                  <hr class="mt-10 mb-10">
                  <div class="ml-3">
                    <h3>Tambahkan Pegawai:</h3>
                    <label>(Silahkan tambahkan pegawai jika data rekan/bawahan anda belum muncul)</label>
                  </div>
                  <v-row class="ml-3 mt-5">
                    <v-col cols="9">
                      <v-autocomplete dense :items="listSKPD" :rules="[rules.required]" @change="loadListPegawaiSKPD" v-model="kode_skpd" item-text="text" item-value="value" label="Pilih SKPD" ></v-autocomplete>
                    </v-col>
                    <v-col cols="3">
                    </v-col>
                  </v-row>
                  <v-row class="ml-3">
                    <v-col cols="9">
                      <v-autocomplete
                        :items="listPegawaiSKPD"
                        v-model="modelListPegawaiSKPD"
                        label="Tambah Assignment Pegawai"
                        :item-text="getNipNama"
                        multiple
                        chips
                        return-object
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="3" class="mt-4">
                      <v-btn @click="addRow()">Tambah Pegawai</v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="dataPegawaiBaru.length > 0" class="mt-5">
                    <v-col v-for="(peg,i) in dataPegawaiBaru" :key="i" align="center">
                      <base-material-card
                        class="v-card-profile"
                        :avatar="peg.foto"
                        style="width:270px;margin-top:40px"
                      >
                        <v-card-text class="text-center" style="height:150px">
                          <h4 class="display-1 mb-1 orange--text text--darken-2" style="height:55px;">
                            {{peg.nama}}
                          </h4>
                          <h4 class="font-weight-light grey--text mb-2">
                            NIP. {{peg.nip}}
                          </h4>
                          <h4 class="font-weight-light green--text" style="font-size:12px; height:30px">
                            {{peg.jabatan}}
                          </h4>
                          <div class="mt-5">
                            <!-- <v-chip small label :class="reviewStatus(peg)" class="ma-1" dark>{{peg.status}}</v-chip> -->
                          </div>
                        </v-card-text>

                        <v-card-actions class="d-flex justify-center">
                          <div>
                          <v-btn
                          color="cyan darken-4"
                          dark
                          rounded
                          class="mr-0"
                          :loading="loadingEl == i"
                          @click="openDialogEkspektasiPerilaku(peg, i, false)"
                          :small="true"
                          >
                          <span> Isi Ekspektasi Perilaku </span>
                          </v-btn>
                          </div>
                        </v-card-actions>

                      </base-material-card>
                    </v-col>
                  </v-row>
                  <v-row v-else justify="center">
                    <v-col cols="5">
                      <v-alert
                      color="blue-grey"
                      dark
                      dense
                      icon="mdi-account-group"
                      prominent
                    >
                      Tidak ada pegawai
                    </v-alert>
                    </v-col>
                  </v-row>
                </div>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogFormEkspektasiPerilaku" ref="refFormEkspektasiPerilaku" persistent>
      <FormIsiEkspektasiPerilaku ref="formUmpanBalikEkspektasiPerilaku" :triwulan="triwulan" :isUmpanBalik="isUmpanBalik" :dataPegawaiBawahan="dataPegawaiBawahan" :dataEkspektasiBawahan="dataEkspektasiBawahan" @onClose="(x)=>{dialogFormEkspektasiPerilaku=x}"/>
    </v-dialog>
  </v-container>
</template>

<script>
// import _ from 'lodash'
import store from '@/store'
import SKPService from '@/services/SKPService'
// import DebugBtn from '@/components/common/DebugBtn'
// import ConfirmBtn from '@/components/base/ConfirmBtn'
import {defaultRules} from '@/utils/lib'
import _g from '../../global'
import FormIsiEkspektasiPerilaku from './FormIsiEkspektasiPerilaku.vue'
import {local} from '@/store/local'
import axios from 'axios'
import { mapState } from 'vuex'
export default {
  components:{
    // ConfirmBtn,
    // DebugBtn,
    FormIsiEkspektasiPerilaku
  },

  data(){
    return{
      valid:true,
      title:'Isi Ekspektasi Perilaku Kerja',
      forceUpdate:false,
      formDetail:{},
      formValidasi:{},
      formTolakKeterangan:"",
      idTolak:null,
      loading:false,
      detailData:{},
      triwulan:1,
      hides:[],
      dialogDetail:false,
      dialogValidasi:false,
      dialogTolak:false,
      selectedItem:{},
      tabItems:['Triwulan I', 'Triwulan II', 'Triwulan III', 'Triwulan IV', 'Tahunan'],
      tableValues:{},
      loadingBtn:{
        terima:false,
        tolak:false,
        validasi:false,
        dataPegawai:false
      },
      ratingList:['kurang', 'kurang berhasil', 'cukup', 'berhasil', 'sangat berhasil'],
      rules:{
        ...defaultRules,
        min30: v=> (v && v.length > 30) || 'Minimal 30 karakter'
      },
      dialogFormEkspektasiPerilaku: false,
      dataPegawaiBawahan: null,
      dataEkspektasiBawahan:{},
      // dataRespon:{},
      isUmpanBalik: false,
      loadingEl:-1,
      loadingElUmpanBalik:-1,
      dataPegawaiBaru: [],
      listPegawaiSKPD: [],
      modelListPegawaiSKPD: [],
      avatarImages:{},
      listSKPD: [
        {value: "4.03.01", text: "Badan Kepegawaian dan Pengembangan Sumber Daya Manusia"},
        {value: "4.05.06", text: "Badan Kesatuan Bangsa dan Politik"},
        {value: "4.02.01", text: "Badan Keuangan dan Aset Daerah"},
        {value: "4.02.03", text: "Badan Pendapatan Daerah"},
        {value: "4.01.01", text: "Badan Perencanaan Pembangunan, Penelitian dan Pengembangan"},
        {value: "2.17.01", text: "Dinas Arsip dan Perpustakaan"},
        {value: "1.03.02", text: "Dinas Cipta Karya, Bina Konstruksi dan Tata Ruang"},
        {value: "1.05.02", text: "Dinas Kebakaran dan Penanggulangan Bencana"},
        {value: "2.16.01", text: "Dinas Kebudayaan dan Pariwisata"},
        {value: "2.06.01", text: "Dinas Kependudukan dan Pencatatan Sipil"},
        {value: "1.02.01", text: "Dinas Kesehatan"},
        {value: "2.03.01", text: "Dinas Ketahanan Pangan dan Pertanian"},
        {value: "2.01.01", text: "Dinas Ketenagakerjaan"},
        {value: "2.10.01", text: "Dinas Komunikasi dan Informatika"},
        {value: "2.11.01", text: "Dinas Koperasi dan Usaha Kecil dan Menengah"},
        {value: "2.18.01", text: "Dinas Lingkungan Hidup"},
        {value: "2.08.01", text: "Dinas Pemberdayaan Perempuan dan Perlindungan Anak"},
        {value: "2.13.01", text: "Dinas Pemuda dan Olahraga"},
        {value: "2.12.01", text: "Dinas Penanaman Modal dan Pelayanan Terpadu Satu Pintu"},
        {value: "1.01.01", text: "Dinas Pendidikan"},
        {value: "2.02.01", text: "Dinas Pengendalian Penduduk dan Keluarga Berencana"},
        {value: "3.06.01", text: "Dinas Perdagangan dan Perindustrian"},
        {value: "2.09.01", text: "Dinas Perhubungan"},
        {value: "1.08.01", text: "Dinas Perumahan dan Kawasan Permukiman"},
        {value: "1.06.01", text: "Dinas Sosial"},
        {value: "1.03.03", text: "Dinas Sumber Daya Air dan Bina Marga"},
        {value: "4.05.05", text: "Inspektorat Daerah"},
        {value: "4.05.11", text: "Kecamatan Andir"},
        {value: "4.05.29", text: "Kecamatan Antapani"},
        {value: "4.05.27", text: "Kecamatan Arcamanik"},
        {value: "4.05.17", text: "Kecamatan Astana Anyar"},
        {value: "4.05.20", text: "Kecamatan Babakan Ciparay"},
        {value: "4.05.32", text: "Kecamatan Bandung Kidul"},
        {value: "4.05.21", text: "Kecamatan Bandung Kulon"},
        {value: "4.05.13", text: "Kecamatan Bandung Wetan"},
        {value: "4.05.24", text: "Kecamatan Batununggal"},
        {value: "4.05.18", text: "Kecamatan Bojongloa Kaler"},
        {value: "4.05.19", text: "Kecamatan Bojongloa Kidul"},
        {value: "4.05.31", text: "Kecamatan Buahbatu"},
        {value: "4.05.16", text: "Kecamatan Cibeunying Kaler"},
        {value: "4.05.15", text: "Kecamatan Cibeunying Kidul"},
        {value: "4.05.28", text: "Kecamatan Cibiru"},
        {value: "4.05.10", text: "Kecamatan Cicendo"},
        {value: "4.05.08", text: "Kecamatan Cidadap"},
        {value: "4.05.35", text: "Kecamatan Cinambo"},
        {value: "4.05.12", text: "Kecamatan Coblong"},
        {value: "4.05.33", text: "Kecamatan Gedebage"},
        {value: "4.05.26", text: "Kecamatan Kiaracondong"},
        {value: "4.05.23", text: "Kecamatan Lengkong"},
        {value: "4.05.36", text: "Kecamatan Mandalajati"},
        {value: "4.05.34", text: "Kecamatan Panyileukan"},
        {value: "4.05.30", text: "Kecamatan Rancasari"},
        {value: "4.05.22", text: "Kecamatan Regol"},
        {value: "4.05.09", text: "Kecamatan Sukajadi"},
        {value: "4.05.07", text: "Kecamatan Sukasari"},
        {value: "4.05.14", text: "Kecamatan Sumur Bandung"},
        {value: "4.05.25", text: "Kecamatan Ujungberung"},
        {value: "1.02.04", text: "Rumah Sakit Khusus Gigi dan Mulut"},
        {value: "1.02.03", text: "Rumah Sakit Khusus Ibu dan Anak"},
        {value: "1.02.02", text: "Rumah Sakit Umum Daerah (lama)"},
        {value: "1.02.05", text: "Rumah Sakit Umum Daerah"},
        {value: "1.05.01", text: "Satuan Polisi Pamong Praja"},
        {value: "4.05.02", text: "Sekretariat Daerah"},
        {value: "4.05.04", text: "Sekretariat Dewan Perwakilan Rakyat Daerah"}
      ],
      kode_skpd: null,
      triwulan_berjalan:null,
      periode_isi_ekspektasi:null,
      triwulan_enable:null,
      enable:false
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.triwulanFromRoute = to.query.jenis
    })
  },

  watch:{
    triwulan:function(val){
      console.log(val)
      if(this.triwulanFromRoute != null){
        if(val != this.triwulanFromRoute){
          this.triwulan = val
        }else{
          this.triwulan = this.triwulanFromRoute
        }
      }else{
        this.triwulan = val
      }
      this.updateTable()
    },
    dialogFormEkspektasiPerilaku:function(val){
      if(val){
        this.$nextTick(() => {
          this.$refs.refFormEkspektasiPerilaku.scrollTop = this.$refs.refFormEkspektasiPerilaku.scrollHeight
        })
      }
    }
  },

  created(){
    this.year = new Date().getFullYear()-1
    this.triwulan = _g.getTriwulan().toString()
    this.updateTable()
  },

  computed:{
    triwulan_text(){
      return isNaN(this.triwulan ) ? 'Tahunan' : 'Triwulan ' + this.triwulan
    },

    ...mapState({
      username: state=> state.user.current.username,
      user: state=> state.user.dataPegawai,
      current: state=> state.user.current
    }),

  },

  mounted(){
    this.getDataPegawaiSKPD()
  },

  methods:{

    updateTable(){
      this.pegawai=[]
      this.loadingBtn.dataPegawai = true
      // SKPService.getDataValidasiSKPTriwulan(this.triwulan).then(response =>{
      //   // this.responseData = response.data
      //   this.pegawai=response.data.data
      //   this.loadingBtn.dataPegawai = false
      // })
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }

      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_get_data = base_url_api + 'v1/get-list-ekspektasi-perilaku'
      axios.post(url_get_data, {
        nip: current.username,
        bulan: this.triwulan,
      }).then(response => {
        this.pegawai=response.data.data
        this.loadingBtn.dataPegawai = false
        this.triwulan_berjalan = response.data.triwulan_berjalan
        this.periode_isi_ekspektasi = response.data.periode_isi_ekspektasi
        this.triwulan_enable = response.data.triwulan_enable
        this.enable = response.data
      }).finally(()=>{
      }).catch(err => {
        var error = err.message ? err.message : err.response.data.message
        this.loadingEl = -1
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })

      if(current.role != 'walikota'){
        SKPService.getSKP({
          search:'',
          row_per_page:1,
          triwulan:"tahun",
          page:5})
        .then((response)=>{
          // console.log('tahunan',response.data)
          this.itemsTahunan = response.data
          this.dataPegawaiEkspektasiPerilaku = response.data.data[0]
          if(this.tahun >= 2022){
            this.dataPegawaiEkspektasiPerilaku['ekspektasi_pimpinan'] = response.data.ekspektasi_pimpinan != undefined ? response.data.ekspektasi_pimpinan[0] : []
          }
          this.eselon = response.data.eselon
          this.jft = response.data.jft
          console.log(this.itemsTahunan)
        }).finally(()=>{
          this.loadingTahunan = false
        })
      }
    },

    closeDialog(){
      this.formValidasi = {}
      this.$refs.form.resetValidation()
      this.dialogValidasi=false
    },

    openDialogEkspektasiPerilaku(peg, idx, isUmpanBalik){
      this.loadingEl = idx
      this.isUmpanBalik = isUmpanBalik
      this.dataEkspektasiBawahan.e_berorientasi_pelayanan = ''
      this.dataEkspektasiBawahan.e_akuntabel = ''
      this.dataEkspektasiBawahan.e_kompeten = ''
      this.dataEkspektasiBawahan.e_harmonis = ''
      this.dataEkspektasiBawahan.e_loyal = ''
      this.dataEkspektasiBawahan.e_adaptif = ''
      this.dataEkspektasiBawahan.e_kolaboratif = ''
      this.dataEkspektasiBawahan.r_berorientasi_pelayanan = ''
      this.dataEkspektasiBawahan.r_akuntabel = ''
      this.dataEkspektasiBawahan.r_kompeten = ''
      this.dataEkspektasiBawahan.r_harmonis = ''
      this.dataEkspektasiBawahan.r_loyal = ''
      this.dataEkspektasiBawahan.r_adaptif = ''
      this.dataEkspektasiBawahan.r_kolaboratif = ''
      this.dataEkspektasiBawahan.tahun = this.$store.getters["user/data"].tahun
      // var current = store.state.user.data
      var nip = peg.peg_nip ? peg.peg_nip : peg.nip
      // SKPService.getEkspektasiPerilakuKerja(nip, this.triwulan, this.dataEkspektasiBawahan.tahun).then(response=>{
      //   let res =response.data.data
      //   res.forEach(el => {
      //     if(el.nip_atasan == current.username){
      //       this.dataEkspektasiBawahan = el
      //     }
      //   });
      //   // if(res.data[0] != undefined){
      //   //   this.dataEkspektasiBawahan = res.data[0]
      //   // }
      //   this.dataEkspektasiBawahan.foto = peg.foto
      //   this.dataEkspektasiBawahan.nip = peg.nip
      //   this.dataEkspektasiBawahan.jabatan = peg.jabatan
      //   this.dataEkspektasiBawahan.nama = peg.nama
      //   this.dataEkspektasiBawahan.jenis = this.triwulan
      // }).finally(()=>{
      //   // this.dataPegawaiBawahan = peg
      //   if(isUmpanBalik){
      //     this.loadingElUmpanBalik = -1
      //     // this.$refs.formUmpanBalikEkspektasiPerilaku.scrollIntoView({ block: 'end', scrollBehavior: 'smooth' });
      //   }else{
      //     this.loadingEl = -1
      //     // this.$refs.formIsiEkspektasiPerilaku.scrollIntoView({ block: 'top', scrollBehavior: 'smooth' });
      //     // this.$refs.formIsiEkspektasiPerilaku.scrollIntoView({ block: 'start', scrollBehavior: 'smooth' });
      //   }
      //   this.dialogFormEkspektasiPerilaku = true
      //   // this.$nextTick(() => {
      //     // block: 'end' will scroll to top of element instead of bottom
      //     // this.$refs.formUmpanBalikEkspektasiPerilaku.$el.scrollIntoView({ block: 'end', scrollBehavior: 'smooth' });
      //   // });
      // })
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }

      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_get_data = base_url_api + 'v1/get-ekspektasi-perilaku'
      axios.post(url_get_data, {
        nip_atasan: current.username, nip: peg.nip, triwulan: this.triwulan, tahun: this.dataEkspektasiBawahan.tahun
      }).then(response => {
        let res =response.data.data
        if(this.triwulan == 'tahun'){
          if(res[0] != null){
            this.dataEkspektasiBawahan = res[0]
          }
        }else{
          if(res != null){
            this.dataEkspektasiBawahan = res
          }
        }
        this.dataEkspektasiBawahan.foto = peg.foto
        this.dataEkspektasiBawahan.nip = peg.nip
        this.dataEkspektasiBawahan.jabatan = peg.jabatan
        this.dataEkspektasiBawahan.nama = peg.nama
        this.dataEkspektasiBawahan.jenis = this.triwulan
      }).finally(()=>{
        if(isUmpanBalik){
          this.loadingElUmpanBalik = -1
        }else{
          this.loadingEl = -1
        }
        this.dialogFormEkspektasiPerilaku = true
      }).catch(err => {
        var error = err.message ? err.message : err.response.data.message
        this.loadingEl = -1
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    getImg(nip, peg_foto){
      let avatarImgUrl = "https://simpeg.bandung.go.id/uploads/" + peg_foto
      this.avatarImages[nip] = avatarImgUrl
      return avatarImgUrl
    },

    getDataPegawaiSKPD(kolok){
      // let kode_skpd = local.getLocal("kolok") ? local.getLocal("kolok") : this.$store.getters["user/data"].data_asn.kolok
      let kode_skpd = kolok ? kolok : local.getLocal("kolok")
      // SKPService.getPegawaiSKPD(kode_skpd).then(response=>{
      //   response.data.data.forEach(element => {
      //     element.foto = this.getImg(element.nip, element.peg_foto)
      //   });
      //   this.listPegawaiSKPD = response.data.data
      // })
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }

      if(current.role != 'walikota'){
        var base_url_api = _g.getBaseURLAPIERK(current.year)
        const url_get_data = base_url_api + 'v1/get-pegawai-skpd-req-post'
        axios.post(url_get_data, {
          nip: current.username, kode_skpd: kode_skpd
        }).then(response => {
          response.data.data.forEach(element => {
            element.foto = this.getImg(element.nip, element.peg_foto)
          });
          this.listPegawaiSKPD = response.data.data
        }).catch(err => {
          var error = err.message ? err.message : err.response.data.message
          this.loadingEl = -1
          store.commit('snackbar/setSnack',{message: error, color:'error'})
        })
      }
    },

    getNipNama(item) {
      return `${item.peg_nip} - ${item.peg_nama}`;
      // return `${item.nip} - ${item.nama}`;
    },

    addRow(){
      this.no = this.dataPegawaiBaru.length
      this.dataPegawaiBaru = []
      this.modelListPegawaiSKPD.forEach(element => {
        this.no += 1
        var my_object = {
          foto: element.foto,
          nama: element.peg_nama,
          nip: element.peg_nip,
          // peg_nama: element.nama,
          // peg_nip: element.nip,
          jabatan: element.jabatan,
          no:this.no,
          checked: false,
          disabled_assignment: false
        };
        this.dataPegawaiBaru.push(my_object)
      });
    },

    removeItem(item) {
      this.no -= 1
      for (let i = 0; i < this.dataPegawaiBaru.length; i++) {
        const el = this.dataPegawaiBaru[i];
        if(el.no == item.no){
          this.dataPegawaiBaru.splice(i, 1)
        }
      }
      // re-indexing
      for (let i = 0; i < this.dataPegawaiBaru.length; i++) {
        this.dataPegawaiBaru[i].no = i+1
      }
      console.log(this.dataPegawaiBaru)
    },

    loadListPegawaiSKPD(){
      this.getDataPegawaiSKPD(this.kode_skpd)
    }

  },
}
</script>

<style lang="css" scoped>
</style>
